import React from 'react'
import { Link } from 'react-router-dom';

// icons and images
import NotfoundImg from '../assets/img/404.png';

const NotFound = () => {
    return (
        <>
            <main className="main page-404">
                <div className="container">
                    <div className="row align-items-center height-100vh text-center">
                        <div className="col-lg-8 m-auto">
                            <p className="mb-50"><img src={NotfoundImg} alt="" className="hover-up"/></p>
                            <h2 className="mb-30">Page Not Found</h2>
                            <p className="font-lg text-grey-700 mb-30">
                                The link you clicked may be broken or the page may have been removed.<br /> visit the <Link to={'/'}> <span> Homepage</span></Link> or <Link to={'/contact'}><span>Contact us</span></Link> about the problem
                            </p>
                            <Link className="btn btn-dark submit-auto-width font-xs hover-up" to={'/'}>Back To Home Page</Link>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default NotFound