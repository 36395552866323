import logo from './logo.svg';
import './App.css';

import { CartContextProvider } from './context/cart/CartContext'
import { SearchContextProvider } from './context/search/SearchContext'
import { ResProfileContextProvider } from './context/restaurant/ProfileContext'
import { NotificationContextProvider } from './context/notification/NotificationContext'

function App() {
    return (
        <>
            <NotificationContextProvider>
                <ResProfileContextProvider>
                    <SearchContextProvider>
                        <CartContextProvider>
                        </CartContextProvider>
                    </SearchContextProvider>
                </ResProfileContextProvider>
            </NotificationContextProvider>
        </>
    );
}

export default App;
