import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, Link } from 'react-router-dom';

// icons and images
import heroImg from '../assets/img/slider_1.jpg';
import banar1 from '../assets/img/banner_1.jpg';
import banar2 from '../assets/img/banner_2.jpg';
import banar3 from '../assets/img/banner_3.jpg';
import product1 from '../assets/img/product_1.png';
import product2 from '../assets/img/product_2.png';
import product3 from '../assets/img/product_3.png';
import product4 from '../assets/img/product_4.png';
import product5 from '../assets/img/product_5.png';
import product6 from '../assets/img/product_6.png';
import product7 from '../assets/img/product_7.png';
import product8 from '../assets/img/product_8.png';
import product9 from '../assets/img/product_9.png';
import product10 from '../assets/img/product_10.png';
import deal_ofthe_week from '../assets/img/deal_ofthe_week.png';


import { Heart, Star } from "react-bootstrap-icons";

import * as serverURL from '../config/Constants'
import Food from '../components/Food/Food'
import CustomCarousel from '../components/Carousel/Carousel'
import { CartContext } from '../context/cart/CartContext';
import { SearchContext } from '../context/search/SearchContext';
import { ResProfileContext } from '../context/restaurant/ProfileContext';



// MUI library
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const Home = () => {

    const { id } = useParams();

    const [popularFoods, setPopularFoods] = useState([]);
    const [carousels, setCarousels] = useState([]);
    const [categories, setCategories] = useState([]);
    const [specialOffer, setSpecialOffer] = useState([]);
    const [profile, setProfile] = useState([]);

    // import restaurant profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    return (
        <>
            {/* Slider  */}
            <div className="main_slider" style={{ backgroundImage: `url(${heroImg})` }}>
                <div className="container fill_height">
                    <div className="row align-items-center fill_height">
                        <div className="col">
                            <div className="main_slider_content">
                                <h6>Spring / Summer Collection 2017</h6>
                                <h1>Get up to 30% Off New Arrivals</h1>
                                <div className="red_button shop_now_button"><a href="#">shop now</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner  */}
            <div className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="banner_item align-items-center" style={{ backgroundImage: `url(${banar1})` }}>
                                <div className="banner_category">
                                    <a href="categories.html">women's</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner_item align-items-center" style={{ backgroundImage: `url(${banar2})` }}>
                                <div className="banner_category">
                                    <a href="categories.html">accessories's</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner_item align-items-center" style={{ backgroundImage: `url(${banar3})` }}>
                                <div className="banner_category">
                                    <a href="categories.html">men's</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Arrivals  */}
            <div className="new_arrivals">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="section_title new_arrivals_title">
                                <h2>New Arrivals</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col text-center">
                            <div className="new_arrivals_sorting">
                                <ul className="arrivals_grid_sorting clearfix button-group filters-button-group">
                                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center active is-checked" data-filter="*">all</li>
                                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center" data-filter=".women">women's</li>
                                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center" data-filter=".accessories">accessories</li>
                                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center" data-filter=".men">men's</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Grid container>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product1} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>

                        <Grid item xs={2.4}>
                            <div className="product-item women">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product2} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item women">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product3} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product4} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product5} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product6} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product7} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product8} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product9} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>
                        <Grid item xs={2.4}>
                            <div className="product-item men">
                                <div className="product discount product_filter">
                                    <div className="product_image">
                                        <img src={product10} alt="" />
                                    </div>
                                    <div className="favorite favorite_left"></div>
                                    <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                                    <div className="product_info">
                                        <h6 className="product_name"><a href="single.html">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                                        <div className="product_price">$520.00<span>$590.00</span></div>
                                    </div>
                                </div>
                                <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>

            {/* Deal of the week  */}
            <div className="deal_ofthe_week">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="deal_ofthe_week_img">
                                <img src={deal_ofthe_week} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 text-right deal_ofthe_week_col">
                            <div className="deal_ofthe_week_content d-flex flex-column align-items-center float-right">
                                <div className="section_title">
                                    <h2>Deal Of The Week</h2>
                                </div>
                                <ul className="timer">
                                    <li className="d-inline-flex flex-column justify-content-center align-items-center">
                                        <div id="day" className="timer_num">03</div>
                                        <div className="timer_unit">Day</div>
                                    </li>
                                    <li className="d-inline-flex flex-column justify-content-center align-items-center">
                                        <div id="hour" className="timer_num">15</div>
                                        <div className="timer_unit">Hours</div>
                                    </li>
                                    <li className="d-inline-flex flex-column justify-content-center align-items-center">
                                        <div id="minute" className="timer_num">45</div>
                                        <div className="timer_unit">Mins</div>
                                    </li>
                                    <li className="d-inline-flex flex-column justify-content-center align-items-center">
                                        <div id="second" className="timer_num">23</div>
                                        <div className="timer_unit">Sec</div>
                                    </li>
                                </ul>
                                <div className="red_button deal_ofthe_week_button"><a href="#">shop now</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Best Sellers */}

            <div className="best_sellers">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="section_title new_arrivals_title">
                                <h2>Popular Categories</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="product_slider_container">
                                <div className="owl-carousel owl-theme product_slider">

                                    {/* Slide 1  */}

                                    <div className="owl-item product_slider_item" >
                                        <div className="product-item">
                                            <div className="product">
                                                <div className="product_image">
                                                    <img src={product1} alt="" />
                                                </div>
                                                {/* <div className="favorite"></div> */}
                                                {/* <div className="product_bubble product_bubble_left product_bubble_green d-flex flex-column align-items-center"><span>new</span></div> */}
                                                <div className="product_info">
                                                    <h6 className="product_name"><a href="single.html">Kitchen</a></h6>
                                                    {/* <div className="product_price">$610.00</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Slide 1  */}

                                    <div className="owl-item product_slider_item" >
                                        <div className="product-item">
                                            <div className="product">
                                                <div className="product_image">
                                                    <img src={product1} alt="" />
                                                </div>
                                                {/* <div className="favorite"></div> */}
                                                {/* <div className="product_bubble product_bubble_left product_bubble_green d-flex flex-column align-items-center"><span>new</span></div> */}
                                                <div className="product_info">
                                                    <h6 className="product_name"><a href="single.html">Kitchen</a></h6>
                                                    {/* <div className="product_price">$610.00</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="owl-item product_slider_item" >
                                        <div className="product-item">
                                            <div className="product">
                                                <div className="product_image">
                                                    <img src={product1} alt="" />
                                                </div>
                                                <div className="product_info">
                                                    <h6 className="product_name"><a href="single.html">Dining</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="owl-item product_slider_item" >
                                        <div className="product-item">
                                            <div className="product">
                                                <div className="product_image">
                                                    <img src={product1} alt="" />
                                                </div>
                                                <div className="product_info">
                                                    <h6 className="product_name"><a href="single.html">Living</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="owl-item product_slider_item" >
                                        <div className="product-item">
                                            <div className="product">
                                                <div className="product_image">
                                                    <img src={product1} alt="" />
                                                </div>
                                                <div className="product_info">
                                                    <h6 className="product_name"><a href="single.html">Furniture</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="owl-item product_slider_item" >
                                        <div className="product-item">
                                            <div className="product">
                                                <div className="product_image">
                                                    <img src={product1} alt="" />
                                                </div>
                                                <div className="product_info">
                                                    <h6 className="product_name"><a href="single.html">Decore</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Slider Navigation */}

                                <div className="product_slider_nav_left product_slider_nav d-flex align-items-center justify-content-center flex-column">
                                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                </div>
                                <div className="product_slider_nav_right product_slider_nav d-flex align-items-center justify-content-center flex-column">
                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Benefit  */}

            <div className="benefit">
                <div className="container">
                    <div className="row benefit_row">
                        <div className="col-lg-3 benefit_col">
                            <div className="benefit_item d-flex flex-row align-items-center">
                                <div className="benefit_icon"><i className="fa fa-truck" aria-hidden="true"></i></div>
                                <div className="benefit_content">
                                    <h6>free shipping</h6>
                                    <p>Suffered Alteration in Some Form</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 benefit_col">
                            <div className="benefit_item d-flex flex-row align-items-center">
                                <div className="benefit_icon"><i className="fa fa-money" aria-hidden="true"></i></div>
                                <div className="benefit_content">
                                    <h6>cach on delivery</h6>
                                    <p>The Internet Tend To Repeat</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 benefit_col">
                            <div className="benefit_item d-flex flex-row align-items-center">
                                <div className="benefit_icon"><i className="fa fa-undo" aria-hidden="true"></i></div>
                                <div className="benefit_content">
                                    <h6>45 days return</h6>
                                    <p>Making it Look Like Readable</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 benefit_col">
                            <div className="benefit_item d-flex flex-row align-items-center">
                                <div className="benefit_icon"><i className="fa fa-clock-o" aria-hidden="true"></i></div>
                                <div className="benefit_content">
                                    <h6>opening all week</h6>
                                    <p>8AM - 09PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home