import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

//import Icons
import cartIcon from '../../assets/img/svgs/cart.svg';
import userIcon from '../../assets/img/svgs/user.svg';
import { Person, Cart, List, Bell, HouseDoor, X, XLg } from "react-bootstrap-icons";

// providers
import './Header.css';
import * as serverURL from '../../config/Constants'
import { SearchContext } from '../../context/search/SearchContext';
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import { NotificationContext } from '../../context/notification/NotificationContext';
import { CartContext } from '../../context/cart/CartContext';

// import custom hook of authentication
import { useAuth } from '../../context/auth/AuthContext';

// MUI Library
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const Header = () => {

    const { user, setUser } = useAuth();
    const [showSearch, setShowSearch] = useState(false);
    const [active, setActive] = useState('');
    // logout comfirmation dailog c
    const [dailogOpen, setDailogOpen] = useState(false);


    // get the route name
    const location = useLocation();
    const currentRoute = location.pathname.split('/')[1];
    // end of route name

    // activeate the page icon to blue
    useEffect(() => {
        if (currentRoute == '/') {
            setActive('')
        } else if (currentRoute == 'about') {
            setActive('about')
        } else if (currentRoute == 'contact') {
            setActive('contact')
        } else if (currentRoute == 'profile') {
            setActive('profile')
        }
    }, [])
    // end

    // logout user
    const handleLogout = async () => {
        localStorage.removeItem('royabranduser');
        window.location.href = '/';
    };

     // logout confirmation dailog
    const handleDailogClose = () => {
        setDailogOpen(false);
    }
    return (
        <>
            {/* Header  */}
            <header className="header trans_300">

                {/* Top Navigation  */}
                <div className="top_nav">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="top_nav_left">free shipping on all u.s orders over $50</div>
                            </div>
                            <div className="col-md-6 text-right">
                                <div className="top_nav_right">
                                    <ul className="top_nav_menu">

                                        {/* Currency / Language / My Account  */}

                                        <li className="currency">
                                            <a href="#">
                                                usd
                                                <i className="fa fa-angle-down"></i>
                                            </a>
                                            <ul className="currency_selection">
                                                <li><a href="#">usd</a></li>
                                            </ul>
                                        </li>
                                        <li className="language">
                                            <a href="#" >
                                                English
                                                <i className="fa fa-angle-down"></i>
                                            </a>
                                            <ul className="language_selection">
                                                <li>Spanish</li>
                                                <li>Spanish</li>
                                            </ul>
                                        </li>

                                        <li className="account">
                                            <a href="#">
                                                My Account
                                                <i className="fa fa-angle-down"></i>
                                            </a>

                                            {user ? (
                                                <ul className="account_selection" style={{textAlign : 'left'}}>
                                                    <li><Link to={'/profile'} ><i className="fa fa-user-circle" aria-hidden="true"></i>Profile</Link></li>
                                                    <li><Link onClick={() => setDailogOpen(true)} ><i className="fa fa-sign-out" aria-hidden="true" ></i>Sign Out</Link></li>
                                                </ul>
                                            ) : (
                                                <ul className="account_selection">
                                                    <li><Link to={'/signin'}><i className="fa fa-sign-in" aria-hidden="true"></i>Sign In</Link></li>
                                                    <li><Link to={'/signup'}><i className="fa fa-user-plus" aria-hidden="true"></i>Register</Link></li>
                                                </ul>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >

                {/* Main Navigation  */}

                < div className="main_nav_container" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-right">
                                <div className="logo_container">
                                    <a href="#">Roya<span>Brand.</span></a>
                                </div>
                                <nav className="navbar">
                                    <ul className="navbar_menu">
                                        <li className={`${active == '' && "active"}`}>
                                            <Link to={"/"}
                                                onClick={() => setActive('')}
                                                className={`nav-link`}>
                                                Home
                                            </Link>
                                        </li>
                                        <li className={`${(active == 'roya-home-kitchen' || active == 'roya-home-dining') && "active"} nav-item dropdow`}>
                                            <Link className={`nav-link dropdown-toggle`}>
                                                Roya Home <i className="fi-rs-angle-down"></i>
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li className={`${active == 'roya-home-kitchen' && "active"}`}>
                                                    <Link to={"/categories/kitchen"}
                                                        onClick={() => setActive('roya-home-kitchen')}
                                                        className={`dropdown-item`}>
                                                        Kitchen
                                                    </Link>
                                                </li>
                                                <li className={`${active == 'roya-home-dining' && "active"}`}>
                                                    <Link to={"/categories/dining"}
                                                        onClick={() => setActive('roya-home-dining')}
                                                        className={`dropdown-item`}>
                                                        Dining
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/categories/living"}
                                                        onClick={() => setActive('roya-home-living')}
                                                        className={`dropdown-item`}>
                                                        Living
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/categories/furniture"}
                                                        onClick={() => setActive('roya-home-furniture')}
                                                        className={`dropdown-item`}>
                                                        Furniture
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/categories/decor"}
                                                        onClick={() => setActive('roya-home-decor')}
                                                        className={`dropdown-item`}>
                                                        Decor
                                                    </Link>
                                                </li>

                                            </ul>
                                        </li>

                                        <li className={`${active == 'roya-fashion' && "active"}`}>
                                            <Link to={"/roya-fashion"}
                                                onClick={() => setActive('roya-fashion')}
                                                className={`nav-link`}>
                                                Roya Fashion
                                            </Link>
                                        </li>
                                        <li className={`${active == 'about' && "active"}`}>
                                            <Link to={"/about"}
                                                onClick={() => setActive('about')}
                                                className={`nav-link`}>
                                                About
                                            </Link>
                                        </li>
                                        <li className={`${active == 'contact' && "active"}`}>
                                            <Link to={"/contact"}
                                                onClick={() => setActive('contact')}
                                                className={`nav-link`}>
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="navbar_user">
                                        {!showSearch ? (
                                            <li><a ><i className="fa fa-search" aria-hidden="true" onClick={() => setShowSearch(true)}></i></a></li>
                                        ) : (
                                            <li >
                                                <div class="form-group has-search">
                                                    <span class="fa fa-search form-control-search"></span>
                                                    <input type="text" class="form-control" placeholder="Search" autoFocus onBlur={() => setShowSearch(false)} />
                                                </div>
                                            </li>
                                        )}
                                        <li className="checkout ms-1">
                                            <a href="#">
                                                <i className="fa fa-heart" aria-hidden="true"></i>
                                                <span id="checkout_items" className="checkout_items">2</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="hamburger_container">
                                        <i className="fa fa-bars" aria-hidden="true"></i>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div >
            </header >

            {/* mobile navigation */}
            < div className="fs_menu_overlay" ></div >
            <div className="hamburger_menu">
                <div className="hamburger_close"><i className="fa fa-times" aria-hidden="true"></i></div>
                <div className="hamburger_menu_content text-right">
                    <ul className="menu_top_nav" style={{ textAlign: 'left' }}>
                        <li className="menu_item has-children" >
                            <a href="#">
                                usd
                                <i className="fa fa-angle-down"></i>
                            </a>
                            <ul className="menu_selection">
                                <li ><a href="#">cad</a></li>
                                <li><a href="#">aud</a></li>
                                <li><a href="#">eur</a></li>
                                <li><a href="#">gbp</a></li>
                            </ul>
                        </li>
                        <li className="menu_item has-children">
                            <a href="#">
                                English
                                <i className="fa fa-angle-down"></i>
                            </a>
                            <ul className="menu_selection">
                                <li><a href="#">French</a></li>
                                <li><a href="#">Italian</a></li>
                                <li><a href="#">German</a></li>
                                <li><a href="#">Spanish</a></li>
                            </ul>
                        </li>
                        <li className="menu_item has-children">
                            <a href="#">
                                My Account
                                <i className="fa fa-angle-down"></i>
                            </a>
                            <ul className="menu_selection">
                                <li><a href="#"><i className="fa fa-sign-in" aria-hidden="true"></i>Sign In</a></li>
                                <li><a href="#"><i className="fa fa-user-plus" aria-hidden="true"></i>Register</a></li>
                            </ul>
                        </li>
                        <li className="menu_item"><a href="#">home</a></li>
                        <li className="menu_item"><a href="#">shop</a></li>
                        <li className="menu_item"><a href="#">promotion</a></li>
                        <li className="menu_item"><a href="#">pages</a></li>
                        <li className="menu_item"><a href="#">blog</a></li>
                        <li className="menu_item"><a href="#">contact</a></li>
                    </ul>
                </div>
            </div>


            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to sign out?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>No</Button>
                    <Button onClick={handleLogout} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm sign out dialog */}
        </>
    )
}

export default Header