import { createBrowserRouter } from 'react-router-dom';
import Layout from '.././components/layout/Layout'
import Home from '.././pages/Home'
import Contact from '../pages/contact/Contact'

import Cart from '.././pages/cart/Cart'
import Notification from '.././pages/notification/Notification'
import NotFound from '.././pages/NotFound'

import Profile from '.././pages/profile/Profile'
import Feedback from '.././pages/profile/feedback/Feedback'
import About from '.././pages/profile/about/About'
import Privacy from '.././pages/profile/privacy/Privacy'
import UserProfile from '.././pages/profile/user/User'


import Categories from '.././pages/categories/Categories'
import Foods from '.././pages/food-list/FoodList'
import FoodDetails from '.././pages/food-details/FoodDetails'

// Auth pages
import SignUp from '.././pages/auth/signup/SignUp'
import SignIn from '.././pages/auth/signin/SignIn'
import Verify from '.././pages/auth/verify/Verify'

// providers
import { useAuth } from '../context/auth/AuthContext';

const createRoutes = () => {
	// const { user } = useAuthInRoutes();

	const routes = [
		{
			path: '/',
			element: <Layout />,
			children: [
				{
					path: '/',
					element: <Home />,
				},
				{
					path: '/about',
					element: <About />,
				},
				{
					path: '/contact',
					element: <Contact />,
				},
				{
					path: '/signin',
					element: <SignIn />,
				},
				{
					path: '/signup',
					element: <SignUp />,
				},
				{
					path: '/email/verify',
					element: <Verify />,
				},
			],
		},
		{
			path: '/',
			children: [

				{
					path: '/*',
					element: <NotFound />,
				},

			],
		},
	];

	if (localStorage.getItem('royabranduser') != null) {
		routes[0].children.push(
			{
				path: '/profile',
				element: <Profile />,
			},
		);
	}

	return routes;
};

export default createBrowserRouter(createRoutes());

