import React, { useState, useEffect, useContext } from 'react'
import './Profile.css'
import { Link } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// import provides
import { useAuth } from '../../context/auth/AuthContext';

// components
import * as serverURL from '../../config/Constants'
import { ResProfileContext } from '../../context/restaurant/ProfileContext';

// MUI Library
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const Profile = () => {
    const { user } = useAuth();

    // import restaurant profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    const [dailogOpen, setDailogOpen] = useState(false);
    const [custStats, setCustStats] = useState(JSON.parse(localStorage.getItem('izicuststatistics')));

    // logout user
    const handleLogout = async () => {
        localStorage.removeItem('royabranduser');
        window.location.href = '/';
    };
    const handleDailogClose = () => {
        setDailogOpen(false);
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box
                sx={{ marginTop: 25 }}
            >
                <div className="container d-md-flex flex-md-row col-lg-8">
                    <div className='custom-tab col-lg-3'>
                        <ul className="nav nav-tabs d-md-inline-flex align-self-md-start flex-md-column col-lg-12" id="myTab" role="tablist" >
                            <li className="nav-item">
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-expanded="true"><i class="fa fa-bars"></i> Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"><i class="fa fa-regular fa-heart"></i> Wishlist</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="home-tab1" data-toggle="tab" href="#home" role="tab" aria-controls="home"><i class="fa fa-user"></i> Account details</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab1" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"><i class="fa fa-sign-out"></i> Logout</a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content py-3 px-md-3 py-md-0" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="mb-0">Hello Rosie! </h5>
                                </div>
                                <div class="card-body">
                                    <p>From your account dashboard. you can easily check &amp; view your <a href="#">recent orders</a>, manage your <a href="#">shipping and billing addresses</a> and <a href="#">edit your password and account details.</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                        </div>
                    </div>
                </div>
            </Box>
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to sign out?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>No</Button>
                    <Button onClick={handleLogout} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm sign out dialog */}
        </>
    )
}

export default Profile